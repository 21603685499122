<template>
  <div class="home">
    <LoggedForm msg="Welcome to Your LoggedForm App"/>
  </div>
</template>

<script>
import LoggedForm from '@/components/LoggedForm.vue'
  export default {
    name: 'Logged',
    props: {
      //
    },
    components: {
      LoggedForm
    },
    data: () => ({
      //
    }),
    methods: {
      initialize: function () {
        //
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>
